/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr, toTitleCase } from "./utils"
 import { useLocation } from "@reach/router"


 import _ from "lodash"
 export { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "./utils";
 
 const SeoResults = ({ description, lang, meta, title, searchParams, location, pcategorytype }) => {
 
   const {areas, bedrooms, pType, type, price, commType, mustInclude } = searchParams;

  
   var replace_url = pcategorytype+"_"+pType;
 
  //  const actionStr = ACTION_TYPES[replace_url];
   const actionStr = ACTION_TYPES[pType];
   const priceStr = getPriceStr(price)
   const bedRooms = searchParams.bedrooms
   const pricerange = searchParams.price
   const searcharea = areas
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let propertyType = ""
 
   if(type == "") {
     propertyType = "properties"
   } else if(type == "apartment") {
     propertyType = "Apartments"
   } else if(type == "house") {
     propertyType = "Houses"
   } else if(type == "bungalow") {
     propertyType = "bungalows"
   }
   else if(type == "maisonette") {
    propertyType = "Maisonettes"
  } 
  else if(type == "office") {
    propertyType = "Offices"
  } 
   else {
     propertyType = capitalize(type.replace(/-/g, ' '))
   }
   let desc = "";
   if(searcharea === "Bath") {
    desc=`Explore our range of property ${actionStr} ${toTitleCase(areas.replace(/-/g, ' '))}. Contact our property experts to find the right property ${actionStr} ${toTitleCase(areas.replace(/-/g, ' '))}`;
   }else {
    desc = "Explore our latest collection of properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +".  Contact Crisp Cowley in "+toTitleCase(areas.replace(/-/g, ' ')) +" to find the right property for you."
   }
  //  if(propertyType !="properties") {
  //    desc="Looking for a "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+"? David Astburys are here to help you in your properties journey.";
  //  }
  //  if(bedrooms) {
  //   desc = "Explore properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +" with more than "+searchParams.bedrooms+" bedrooms here. Contact our expert real estate brokers today to get assistance in finding the right properties in "+toTitleCase(areas.replace(/-/g, ' '));
  //  }

   if(pricerange.min || pricerange.max) {
       desc=`Locate the right property ${actionStr} ${toTitleCase(areas.replace(/-/g, ' '))} ${priceStr} with Crisp Cowley. Book an appointment with one of our property experts in ${toTitleCase(areas.replace(/-/g, ' '))}`;
   }
   if (propertyType !="properties" && (searchParams.price.min || searchParams.price.max)) {
      desc= "Check out the latest collection of "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+ priceStr+" with David Astburys in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if((pricerange.min || pricerange.max) && bedrooms) {    
    desc = "Explore the list of properties "+ actionStr+" in "+toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" with David Astburys and request a viewing for the properties that interests you."
   } 
   if (propertyType !="properties" && bedrooms) {
     desc = "Find the latest collection of "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms. Contact David Astburys in "+toTitleCase(areas.replace(/-/g, ' '))+", to arrange a viewing.";
   }
   if(propertyType !="properties" && (pricerange.min || pricerange.max) && bedrooms) {
     desc = propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" available through David Astburys. Contact one of our real estate brokers for assistance in finding your properties in "+toTitleCase(areas.replace(/-/g, ' '))+".";
   }

//    else {
//      // price
//      if (price.min || price.max){
//        desc = propertyType + actionStr + areas + "."
//      }
//    }
 
     //
     let titlePropertyType = ""
 
     if(type == "") {
         titlePropertyType = "properties"
     } else if(type == "apartment") {
         titlePropertyType = "apartments"
     } else if(type == "house") {
         titlePropertyType = "houses"
     } else if(type == "flat") {
         titlePropertyType = "flats"
     } else if(type == "property") {
         titlePropertyType = "Property"
     } else if(type == "bungalow") {
         titlePropertyType = "bungalows"
     } else if(type == "land") {
         titlePropertyType = "Land"
     } else if(type == "commercial") {
         titlePropertyType = "Commercial"
     } else {
         titlePropertyType = type
     }
 
     let bedroomType = ""
 
     if ( bedrooms ) {
      bedroomType = ' with more than '+bedrooms+' bedrooms'
    }
 
    let descTitle=title
    //  let descTitle = toTitleCase(titlePropertyType.replace(/-/g, ' ')) + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + bedroomType + priceStr
     //
 
   // console.log("Props Desc => ", desc);
 
   description = desc
 
      // console.log("Props Desc => ", desc);
      var { pathname } = useLocation()

      if (pathname == "/home/" || pathname == "/home" )
        pathname = "/"
      if (pathname == "/property-for-sale" || pathname == "/property-for-sale" )
        pathname = "/property/for-sale/in-london/"
      if (pathname == "/property-to-rent/" || pathname == "property-to-rent" )
        pathname = "/property/to-rent/in-london/"
    
      var curentUrl = '';
      curentUrl = process.env.GATSBY_SITE_URL?.replace(".com/", ".com")+pathname;
    
      // set no index for result pagination
      let hasPagination = false;
      if (pathname !== undefined && pathname) {
        let pageStr = pathname.split('page-');
        hasPagination = pageStr[1] || false;
        if (pageStr[0] !== undefined) {
         curentUrl = process.env.GATSBY_SITE_URL?.replace(".com/", ".com") + pageStr[0];
        }
      }
    
      if (!curentUrl.endsWith('/'))
            curentUrl = curentUrl + '/';
    
      //remove multiple slashes
      curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
      //set lowercase url
      curentUrl = curentUrl.toLowerCase();
   
   
   let metaItems = [
     {
       name: `description`,
       content: description,
     },
     {
       property: `og:title`,
       content: descTitle,
     },
     {
       property: `og:description`,
       content: description,
     },
     {
       property: `og:type`,
       content: `website`,
     },
     {
      name: `og:url`,
      content: curentUrl,
    },

     {
       name: `twitter:card`,
       content: `summary`,
     },
     {
       name: `twitter:creator`,
       content: `Crisp Cowley`,
     },
     {
       name: `twitter:title`,
       content: descTitle,
     },
     {
       name: `twitter:description`,
       content: description,
     },
   ].concat(meta);
 
 
   // set no index for result pagination
  //  let hasPagination = false;
  //  if (location !== undefined && location.pathname) {
  //    let pageStr = location.pathname.split('page-');
  //    hasPagination = pageStr[1] || false;
  //  }
  //  if ( hasPagination ) {
  //    metaItems.push({
  //      name: `robots`,
  //      content: `noindex`,
  //    })
  //  }
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       link={[
        {
          href: curentUrl,
          rel: "canonical"
       }]}
       title={descTitle}
       titleTemplate={`%s | Crisp Cowley`}
       meta={metaItems}
     />
   )
 }
 
 SeoResults.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 SeoResults.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default SeoResults
 