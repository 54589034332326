import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";


export const NoResultProperties = (status, department) => {

    const query = status === "lettings" ? Queries.NoResultPropertiesLettings : Queries.NoResultProperties

    return useQuery(query, {
        variables: {status: status, department: department}
    });
}
