import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"

export const getPriceStr = ( price ) => {
  let priceStr = '';
  if (price.min && price.max) {
      priceStr = " between " + `£` + addCommas(price.min) + " and " + `£` + addCommas(price.max);
  } else if (price.max) {
      priceStr = " under " + `£` + addCommas(price.max);
  } else if (price.min) {
      priceStr = " over " + `£` + addCommas(price.min);
  }
  return priceStr;
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split('.');
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

// export const ACTION_TYPES = {
//   residential_sales: " for sale in ",
//   residential_lettings: " to rent in ",
// }
export const ACTION_TYPES = {
  sales: " for sale in ",
  lettings: " to rent in ",
}

export const ACTION_DESC_TYPES = {
  residential_sales: " for sale ",
  residential_lettings: " to rent ",
}

// export const pTypeBase = {
//   residential_sales: "/property/for-sale/",
//   residential_lettings: "/property/to-rent/",
// }
export const pTypeBase = {
  sales: "/property/for-sale/",
  lettings: "/property/to-rent/",
}

export const ACTION_DESC_TYPES_INTRO = {
  sales: " for sale in ",
  lettings: " to rent in ",
}


export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location, pcategorytype) => {
  //console.log("$$$$$$", pcategorytype, pType);

  // var replace_url = pcategorytype+"_"+pType;

  if (location) {
      var query = '';
      // let pathUri_main = location.split(pTypeBase[replace_url].slice(0, -1))
      let pathUri_main = location.split(pTypeBase[pType].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var typeVal = ''
      var mustIncludeVal = ''

      //console.log("$$$$$---url---####--", location+"----"+pathUri_main+"----"+pTypeBase+"-----"+pType+"----"+pcategorytype);

     // console.log("price__ 0",location, pType, pTypeBase[pType], pcategorytype, pathUri_main);
      
      //console.log("pathUri_main", pathUri_main)

      if( pathUri_main[1] ) { // following could be regexp
        let pathUri = pathUri_main[1].split('/');
        
        //console.log("price__ 1", pathUri.length);

        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          // for-sale
          if ( pathUri[vi].indexOf("for-sale") >= 0 ) {
            // its area
            pType = 'sales';
          }
          if ( pathUri[vi].indexOf("to-rent") >= 0 ) {
            // its area
            pType = 'lettings';
          }
          if ( pathUri[vi].indexOf("in-") == 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replace("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }
          if ( pathUri[vi].indexOf("studio") >= 0 ) {
            // its studio bedroom
            bedVal = 0
          }

          //console.log("price__", pathUri[vi].indexOf("for-sale"));

          if ( pathUri[vi].indexOf("between-") >= 0 || 
               pathUri[vi].indexOf("over-") >= 0 || 
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }
          // Must Include
          if (pathUri[vi].indexOf("with-") >= 0) {
            var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
            mustIncludeVal = mustIncludeArrfilt.split("-and-")
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            typeVal = priceFilt5[1]
          }
        }
      }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
        areaVal = "London"; // TODO
    }

    var areaAlias = areaVal.replace(' ', '-');

    //console.log("$$$$$$$---parse---", pType, typeVal, minpriceVal, maxpriceVal)

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      pcategorytype:pcategorytype,
      type: typeVal,
      mustInclude: mustIncludeVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {
  const {areas, bedrooms, pType, type, price, mustInclude} = params;

  return {
    department: "residential",
    searchType: pType,
    areas: areas,
    bedroom: bedrooms,
    minPrice:  price.min,
    maxPrice:  price.max,
    building: type,
    must_Include: mustInclude,
  }
}


// TODO: hard coded details to be removed/replaced with common variables
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const propertyH1 = (params, pcategorytype) => {
  // console.log('params : ' + params);

  const {areas, bedrooms, pType, type, price, extra, mustInclude} = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype+"_"+pType;

  let h1 = "";
  let building = "";

  //console.log("pcategorytype", pType, type, pcategorytype, price, bedrooms, areas)

  if ('commercial' === pcategorytype) { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if (type) {
      building = ` ${type} Properties`;
    } else {
      building = `Commercial Properties `;
    }

    h1 += toTitleCase(building);

    // h1 += ACTION_TYPES[replace_url];
    h1 += ACTION_TYPES[params.pType];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

  } else if('auction_commercial' === pcategorytype || 'auction_residential' === pcategorytype){
    building = `Auction Properties `;

    console.log("price ==>", price)

    h1 += toTitleCase(building);
    // h1 += ACTION_TYPES[replace_url];
    h1 += ACTION_TYPES[params.pType];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

  } else {
    let building = "Properties";
    if (bedrooms)
      building = "properties";



    // if (type) {
    //   building = ` ${type} `;
    // } else {
    //   building = ` Properties `;
    // }
    // if(building == "flat") {
    //   building = "Flats";
    // }
    // if(building == "house") {
    //   building = "Houses";
    // }
    // if(building == "apartment") {
    //   building = "Apartments";
    // }
    if(type=="house"){
      building = "Houses";
    }
    if(type == "flat") {
      building = "Flats";
    }
    if(type == "apartment") {
      building = "Apartments";
    }
    if(type == "bungalow") {
      building = "Bungalows";
    }
    h1 += toTitleCase(building);
    // h1 += ACTION_TYPES[replace_url];
    h1 += ACTION_TYPES[params.pType];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    }
    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+' : '+';
      h1 += ' with more than '+bedrooms+' bedrooms'
    }


    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }
  }

  // console.log("H1 title => ", h1);
  return h1;
}

export const propertyDesc = (params, pcategorytype) => {
  // console.log('params : ' + params);

  const {areas, bedrooms, pType, type, price, extra, mustInclude, department} = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype+"_"+pType;
  let h1 = "";
  let building = "";

  if ('commercial' === department) { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if (type)
      building += ` ${type} `;
    else
      building += ` Property `;

    h1 += `${building} in `;

    if (areas) {
      h1 += areas;
    }

    h1 += ` for sale and rent`;

  } else {
    //console.log('departmenttyp', department, params)
    let building = "Properties";
    if (bedrooms) {
      building = "properties";
    }
     

    if (department === "new_developments") {
      building = "New Homes";

      if (bedrooms){ building = "new homes"; }
      
    }

    
    
    if (type) {
      building = ` ${type.toLowerCase()}s`;
      if (department === "new_developments") {
        building = 'New Homes '+ toTitleCase(type);
      }
    } else {
      building = ` properties `;
      if (department === "new_developments") {
        building = ` New Homes `;
      }
    }
    


    h1 += building;
    h1 += ACTION_DESC_TYPES_INTRO[pType];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    }
    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+' : '+';
      h1 += ' with more than '+bedrooms+' bedrooms'
    }
    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }
  }

  // console.log("H1 title => ", h1);
  return h1;
}
