import React,{useState, useEffect} from 'react'
import Layout from "../components/layout"
import PropertyCard from '../components/PropertyCard/PropertyCard'
import SearchResults from "../components/SearchResults/searchResults";
import PropertyFilter from '../components/PropertyFilter/PropertyFilter'
import PropertyNoResult from "../components/PropertyNoResult/PropertyNoResult"
import qs from "qs"
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
// import GoogleBanner from '../components/GoogleBanner/GoogleBanner'
import algoliasearch from "algoliasearch/lite"
import { isMobile } from 'react-device-detect'
import $ from "jquery";
// 
import {
  connectStats,
  SortBy,
  connectInfiniteHits,
  InstantSearch,
  RefinementList,
  connectSearchBox,
  connectMenu,
  connectHits,
  connectRange,
  Configure,
  ToggleRefinement,
  connectSortBy,
  connectAutoComplete,
  ClearRefinements,
  connectPagination,
  connectToggleRefinement,
  Pagination
} from "react-instantsearch-dom"
import Cookies from 'universal-cookie'
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import Select from "react-select"
import SeoResults, { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "../components/property-search/seoResults"
import { parseSearchUrl, propertyH1, propertyDesc } from "../components/property-search/utils";
import { AvailabilityLettings, AvailabilitySales, resiSalesPropertyType, resiLettingsPropertyType, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange } from "../components/Common/propertyUtils";
// 

// 
const mycookies = new Cookies();
const updateAfter = 500
const DEBOUNCE_TIME = 500;

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

var includeSold = false;
var includeLet = false;
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
// 

// Property results loop
const InfiniteHits = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext, location, gridview,nbHits }) => {
  var url_main = typeof window !== 'undefined' ? window.location.pathname : ''
  var page = url_main.split("/").filter(c => c.includes("page-")).length > 0 ? url_main.split("/").filter(c => c.includes("page-"))[0].split("page-").map(c => c)[1] : 1
  return (
    <>
      {/* {(hits.length > 0 && hasPrevious) && (
        <button
          id="myprevbut"
          onClick={refinePrevious}
          disabled={!hasPrevious}
          className="d-none property_show_more_btn"
        >
          Show prev
        </button>
      )} */}

      {
        hits.map((hit, i) => {

          // property details url structure
          let uriStr = "property-for-sale/";
          let mysale = "";
          let stattstxt = ""
          if (hit.search_type === "sales" && (hit.department === 'residential') || (hit.department === 'new_developments')) {
            uriStr = `property-for-sale/`
            mysale = `myResSaleProp`
            stattstxt = `for sale`
          } else if (hit.search_type === "lettings") {
            uriStr = `property-to-rent/`
            mysale = `myResRentProp`
            stattstxt = `to rent`
          }
          //Showing strapline instead of title - as per request for this property alone
          let customTitle = hit.title;
          if(hit?.crm_id === '7175ff6a-c2fc-4b88-a617-7495995ae5bd') {
            customTitle = hit?.description;
          }
          // property details url structure
          return (
            <>
              <PropertyCard
                price={"£" + hit.price.toLocaleString()}
                priceQualifier={hit.price_qualifier}
                propertyid={hit.objectID}
                location={hit.display_address}
                description={hit.description}
                bedroomsCount={hit.bedroom}
                bathroomsCount={hit.bathroom}
                floorarea ={hit.floor_area}
                floorarea_type={hit.floorarea_type}
                receptionsCount={hit.reception}
                propertyVideo={hit.virtual_tour}
                propertyImages={hit.images}
                propertyDetailsLink={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                propertySearchType={hit.searchType}
                status={hit.status}
                title={customTitle}
                gridview={gridview}
                day={hit.created}
              />
           
            </>
            
          )
          
        })
      }


      {/* {(hits.length > 0 && hasMore) && (
        <div className="d-flex align-items-center justify-content-center load-more-wrap">
          <div className="form__actions m-0">
            <button className="d-flex "
              id="mynextbut"
              onClick={refineNext}
              disabled={!hasMore}
            >
              <div className='btn btn-dark load-more'>Load More</div>
              
            </button>
          </div>
        </div>
      )} */}
      {isMobile &&
      <div className='pagination-component'>
        <Container>
      <CustomPaginationRender pagenumber={parseInt(page)} />
      </Container>
      </div>
}

    </>
  )
}
const CustomInfiniteHits = connectHits(InfiniteHits)
// Property results loop

// const CustomPagination = ({ currentRefinement, nbPages, refine, createURL, pagenumber }) => {

//   useEffect(() => {
//       if (parseInt(nbPages) <= parseInt(pagenumber)) {
//           $('.page-next').attr('disabled', true);
//       }
//       else {
//           $('.page-next').attr('disabled', false);
//       }

//       if (parseInt(pagenumber) == 1) {
//           $('.page-back').attr('disabled', true);
//       }
//       else {
//           $('.page-back').attr('disabled', false);
//       }
//   }, [pagenumber])


//   $(document).on('click', '.ais-Pagination-link', function () {
//       if (window) {
//           window.scroll({
//               top: -100,
//               behavior: 'smooth'
//           });
//       }
//   });


//   // Pagination select box
//   const paginationselect = () => {
//       var page_val = document.getElementById('pagination-select').value
//       navigate(createURL(page_val))
//       refine(page_val);

//       if (window) {
//           window.scroll({
//               top: -100,
//               behavior: 'smooth'
//           });
//       }
//   }
//   // Pagination select box

//   // Next Pagination Button
//   const nextpage = () => {
//       var next_page_number = parseInt(pagenumber) + 1
//       navigate(createURL(next_page_number))
//       refine(next_page_number);

//       if (window) {
//           window.scroll({
//               top: -100,
//               behavior: 'smooth'
//           });
//       }
//   }
//   // Next Pagination Button

//   // Previous Pagination Button
//   const prevpage = () => {
//       var prev_page_number = parseInt(pagenumber) - 1
//       navigate(createURL(prev_page_number))
//       refine(prev_page_number);

//       if (window) {
//           window.scroll({
//               top: -100,
//               behavior: 'smooth'
//           });
//       }
//   }
//   // Previous Pagination Button

//   return (
//       <Row>
//           <Col md={3} className="d-none d-md-block">
//               <button className="page-back" onClick={() => prevpage()}><i className="page-arrow-back"></i> Previous</button>
//           </Col>
//           <Col md={6} className="text-center">
//               <Pagination
//                   showFirst={false}
//                   showPrevious={false}
//                   showNext={false}
//                   ariaPrevious='Previous page'
//                   first='Next page'
//                   showLast={false}
//                   // defaultRefinement={props.page}
//                   padding={2}
//               />
//           </Col>
//           <Col md={3} className="d-none d-md-block">
//               <button className="page-next" onClick={() => nextpage()}>Next <i className="page-arrow-next"></i></button>
//           </Col>
//       </Row>
//   )
// }

// const CustomPaginationRender = connectPagination(CustomPagination);

const CustomPagination = ({ currentRefinement, nbPages, refine, createURL, pagenumber }) => {

  useEffect(() => {
    if (parseInt(nbPages) <= parseInt(pagenumber)) {
      $('.page-next').attr('disabled', true);
    }
    else {
      $('.page-next').attr('disabled', false);
    }

    if (parseInt(pagenumber) == 1) {
      $('.page-back').attr('disabled', true);
    }
    else {
      $('.page-back').attr('disabled', false);
    }
  }, [pagenumber])


  // Pagination select box
  const paginationselect = () => {
    var page_val = document.getElementById('pagination-select').value
    navigate(createURL(page_val))
    refine(page_val);

    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  }
  // Pagination select box

  // Next Pagination Button
  const nextpage = () => {
    var next_page_number = parseInt(pagenumber) + 1
    navigate(createURL(next_page_number))
    refine(next_page_number);

    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  }
  // Next Pagination Button

  // Previous Pagination Button
  const prevpage = () => {
    var prev_page_number = parseInt(pagenumber) - 1
    navigate(createURL(prev_page_number))
    refine(prev_page_number);

    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  }
  // Previous Pagination Button

  return (nbPages > 1 &&
    <div className='dropdown-page d-flex align-items-center justify-content-between'>
      <div>
        <button className="page-back" onClick={() => prevpage()}></button>
      </div>
      <div className="text-center">
        Page:<select id="pagination-select" onChange={() => paginationselect()}>
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <option value={page}
                selected={pagenumber == page ? "selected" : ""}
              >
                {page}
              </option>
            );
          })}
        </select>of {nbPages}
      </div>
      <div>
        <button className="page-next" onClick={() => nextpage()}></button>
      </div>
    </div>
  )
}

const CustomPaginationRender = connectPagination(CustomPagination);


const CustomPaginationDesktop = () => {
  useEffect(() => {
  document.querySelectorAll(".ais-Pagination a.ais-Pagination-link").forEach(node => {
    // node.href.setAttribute('href', 'javascript:void(0)')
   node.href="javascript:void(0)"
  })
}, [])
  return(
<div className='pagination-component'>
<Pagination />
</div>)
}
const PaginationDesktop = connectPagination(CustomPaginationDesktop);
// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  return (
    <div className="filter-search-input-box">
      <div className="dropdown">
        <i className="icon-location-input"></i>
        <Form.Control type="search" placeholder="Street, area or postcode" id="formBasicEmail"
          onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
          value={currentRefinement.replace(/-/g, ' ')}
        />
      </div>
    </div>
  )
})
// Searchbox

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine, department, type }) => {
  var propertyListDropdown = resiSalesPropertyType;
  if (department === "residential" && type === "lettings") {
    propertyListDropdown = resiLettingsPropertyType;
  }

  return (
    <Select
      name="building"
      options={propertyListDropdown}
      // value={{ value: 0, label: "All" }}
      placeholder="Property Type"
      onChange={(e) => { refine(e.value, e.label) }}
      isSearchable={false}
    />
  )
}

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type


// Availability
const availabilitySelectbox = ({ items, currentRefinement, refine, department, type }) => {
  var propertyListDropdown = AvailabilitySales;
  if (department === "residential" && type === "lettings") {
    propertyListDropdown = AvailabilityLettings;
  }

  return (
    <Select
      name="availabilty"
      options={propertyListDropdown}
      // value={{ value: 0, label: "All" }}
      placeholder="Availability"
      onChange={(e) => { refine(e.value, e.label) }}
      isSearchable={false}
    />
  )
}

const CustomavailabiltySelectbox = connectMenu(availabilitySelectbox)
// Availability




// Property Bedrooms
const bedroomSelectBox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {

  let bedroomvalselect = ""
  if (currentRefinement.max !== undefined) {
    // bedroomvalselect = 0
    bedroomvalselect = currentRefinement.max
  } else if (currentRefinement.min !== undefined) {
    bedroomvalselect = currentRefinement.min
  }

  return (
    <Select
      name="bedroom"
      options={bedroomsRange}
      // value={{ value: "", label: "Any" }}
      placeholder="Bedrooms"
      onChange={(e) => {
        if (e.value === "") {
          refine(e.value)
        } else if (e.value === "0") {
          refine({
            max: e.value,
          })
        } else {
          refine({
            min: e.value,
          })
        }
      }}
      isSearchable={false}
    />
  )
}

const CustombedroomSelectbox = connectRange(bedroomSelectBox)
// Property Bedrooms 

// Min Price
const minpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  type
}) => {
  var minPriceRange = priceminRangeResiSale;
  if (type === "lettings") {
    minPriceRange = priceminRangeResiRent;
  }
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minPriceRange = minPriceRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }

  minpriceval = currentRefinement.min
  return (
    <Select
      name="minprice"
      options={minPriceRange}
      isSearchable={false}
      placeholder="Min Price"
      onChange={(e) => {
        minpriceval = e.value
        if (e.value === "0") {
          if (maxpriceval !== "0") {
            refine({
              max: maxpriceval,
            })
          } else refine(e.value, e.label)
        } else {
          refine({
            min: e.value,
            max: maxpriceval,
          })
        }
      }}
    />
  )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Min Price

// Max Price
const maxpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  type
}) => {
  var maxPriceRange = pricemaxRangeResiSale;
  if (type === "lettings") {
    maxPriceRange = pricemaxRangeResiRent;
  }
  let maxpricedisable = ''
  if (currentRefinement.min !== undefined) {
    maxPriceRange = maxPriceRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
  }
  if (currentRefinement.min == "400000") {
    maxpricedisable = 'disabled'
  }
  maxpriceval = currentRefinement.max
  return (
    <Select
      name="maxprice"
      options={maxPriceRange}
      defaultValue={{ value: "0", label: "Max Price" }}
      placeholder="Max Price"
      classNamePrefix={"select-opt"}
      isSearchable={false}
      onChange={(e) => {
        maxpriceval = e.value
        if (e.value === "0") {
          if (minpriceval) {
            refine({
              min: minpriceval,
            })
          } else refine(e.value, e.label)
        } else {
          refine({
            min: minpriceval,
            max: e.value,
          })
        }
      }}
    />
  )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Max Price

// Sort by
const sortbyStyle = {
  control: base => ({
    ...base,
    boxShadow: "none",
    border: "none",
  }),
}

const CustomSortBy = ({ items, refine, currentRefinement }) => (
  <div className="property-filter__action sort">
    <span className="property-filter__label">Sort: </span>
    <Select
      isSearchable={false}
      styles={sortbyStyle}
      options={items}
      classNamePrefix={"custom-select"}
      className={"custom-select"}
      defaultValue={currentRefinement || ''}
      onChange={(e) => { refine(e.value, e.label) }}
      placeholder={
        <div className="custom-select__placeholder"> {"Most Recent"}</div>
      }
    />
  </div>
);

const CustomSortByFilter = connectSortBy(CustomSortBy);
// Sort by

// Include sold and let
const ToggletttRefinement = ({
  currentRefinement,
  label,
  count,
  refine,
  createURL,
  includeSoldLet,
}) => {

  var include_sold = currentRefinement;
  return (
    label === "Include Sold" ? (
      <a
        href={'javascript:;'}
        onClick={event => {
          event.preventDefault();
          includeSold = !includeSold;
          refine(!currentRefinement);
        }}
      >
        <div className="property-filter__action">
          <input type="checkbox" id="sold" name="sold" checked={includeSold ? "checked" : ""} />
          <label htmlFor="sold" className="property-filter__label">{label}</label>
        </div>
      </a>
    ) : (
      <a
        href={'javascript:;'}
        onClick={event => {
          event.preventDefault();
          includeLet = !includeLet;
          refine(!currentRefinement);
        }}
      >
        <div className="property-filter__action">
          <input type="checkbox" id="sold" name="sold" checked={includeLet ? "checked" : ""} />
          <label htmlFor="sold" className="property-filter__label">{label}</label>
        </div>
      </a>
    )


  )
}

const CustomToggleRefinement = connectToggleRefinement(ToggletttRefinement);
// Include sold and let

// No Results
const NoStats = ({ processingTimeMS, nbHits, location, status, department, OpenMember }) => {
  return (
    <>
      {nbHits === 0 &&
        <>
            <PropertyNoResult uriStr={location} status={status} department={department} OpenMember={OpenMember} />
        </>
      }
    </>
  )
}

const CustomNoStats = connectStats(NoStats)
// No Results

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Create URL
export const createURL = (props, state, location) => {
  let myarea = state.menu


  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom &&
    (state.refinementList && !state.refinementList.status && !state.refinementList.status.length === 0)

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = "in-bath/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var availabilitypath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  const queryParameters = {}
  // Property path
  if (state.menu) {
    if (myarea["building"]) {
      propertypath = "type-" + myarea["building"] + "/"
    }
  }
  // Property path

// console.log("elbin", state?.refinementList?.status)

// if (state?.refinementList?.status) {
    // if (typeof state.refinementList.status === "object") {
    //   if (state.refinementList.status.length > 0) {
    //     var amenities_list = state.refinementList.status.filter(function (item, index, inputArray) {
    //       return inputArray.indexOf(item) == index;
    //     });
    //     if (amenities_list.length > 0) {
    //       availabilitypath = `with-${amenities_list.map(encodeURIComponent).join('-and-')}/`
    //     }
    //   }
    // } else {
    //   availabilitypath = "availability-" + "/"
    // }

    // if (state.refinementList?.amenities?.length > 0) {
    //   amenitiesPath = `with-${state.refinementList?.amenities}/`
    // }
  // }
  //  Availability path
   if (state.refinementList?.status) {
        if (typeof state.refinementList.status === "object") {
      if (state.refinementList.status.length === 2) {
        availabilitypath = "availability-" + "for-sale" + "/"
      }
      else if(state.refinementList.status.length === 3){
        availabilitypath = ""
      }
    } else {
      availabilitypath = "availability-" + "sold"+"/"
    }
    // console.log("elbin",state.refinementList?.status)
    //   availabilitypath = "availability-" + "for-sale" + "/"
    
  }
  // Availability path

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (state.range.hasOwnProperty('bedroom') && parseInt(state.range.bedroom.max) == 0) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/";
    }
    if (state.sortBy === index_name) {
      sortPath = "";
    }
    if (state.sortBy === index_name + "_most_recent") {
      sortPath = "sortby-most-recent/";
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  
  let myUrl = `${areaPath}${propertypath}${availabilitypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`

  // if ("/" === myUrl.substr(myUrl.length - 1))
  //   myUrl = myUrl.substr(0, myUrl.length - 1)

  return `${location}${myUrl}`


}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState, location) =>
  searchState ? `${createURL(props, searchState, location)}` : ""

const urlToSearchState = location => {
  let a1 = location?.pathname?.indexOf("/property/for-sale") > -1
  let a2 = location?.pathname?.indexOf("/property/to-rent") > -1

  if (location) {
    var query = ""
    let pathUri_main = ''


    if (a1 == true) {
      pathUri_main = location.pathname.split("/property/for-sale")
    } else if (a2 == true) {
      pathUri_main = location.pathname.split("/property/to-rent")
    } else {
      pathUri_main = location.pathname.split(location)
    }

    var areaVal = ""
    var bedVal = 10
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var propertyval = ""
    var availabilityval = ""
    var sortVal = ""
    var sortVal_filt = ""
    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Avilability type
        if (pathUri[vi].indexOf("availability-") >= 0) {
          availabilityval = pathUri[vi].replace("availability-", "")
        }
        
        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          areaVal = pathUri[vi].replace("in-", "")
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
          // sortVal = index_name+"_bedrooms"
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name
          }

          // if (sortVal_filt === "most-recent") {
          //   sortVal = index_name + "_most_recent"
          // }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  //console.log("availabilityvalavailabilityval", availabilityval)
  if (propertyval) {
    query += `&menu[building]=` + propertyval
  }

  // Property type
  if(a1){
     if (availabilityval === "sold") {
      query += `&refinementList[status]=` + "Sold"
      query += `&refinementList[status]=` + "Sale Agreed"
    }
    else if(availabilityval === "for-sale") {
      query += `&refinementList[status]=` + "For Sale"
      query += `&refinementList[status]=` + "Sale Agreed"
    }
    else {
      query += `&refinementList[status]=` + "For Sale"
      query += `&refinementList[status]=` + "Sale Agreed"
      query += `&refinementList[status]=` + "Sold"
    }
  } else {
    if (availabilityval === "let") {
      query += `&refinementList[status]=` + "Let"
      query += `&refinementList[status]=` + "Let Agreed"
    }
    else if(availabilityval === "to-let") {
      query += `&refinementList[status]=` + "Let Agreed"
      query += `&refinementList[status]=` + "To Let"
    }
    else {
      query += `&refinementList[status]=` + "Let Agreed"
      query += `&refinementList[status]=` + "To Let"
      query += `&refinementList[status]=` + "Let"
    }
  }


  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    if (areaVal.length < 4) {
      areaVal = '"' + areaVal + '"'
    }
    if (areaVal == "london") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Bedrooms
  if (bedVal == 0) {
    query += `&range[bedroom][min]=` + 0
    query += `&range[bedroom][max]=` + 0
  } else if (bedVal < 10) {
    query += `&range[bedroom][min]=` + bedVal
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate
class SearchResultsTemplate extends React.Component {

  constructor(props) {
    super(props);
  }

  // 
  state = {
    searchState: urlToSearchState(typeof window !== 'undefined' ? window.location : ''),
    userObjects: [],
    mapview: false,
    isOpen: false,
    gridview: isMobile ? true : false,
    popupType: "",
    membDet: "",
    infoText: false,
    addStatus: this.props.pstatustype
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  handleAdditionalStatus=(value)=>{
    if(this.props.ptype === "sales") {
      this.setState({
        addStatus: value === "sold" ? ["Sold", "Sale Agreed"] : value === "for-sale" ? ["For Sale"] : ["For Sale", "Sale Agreed", "Sold"]
      })
    } else {
      this.setState({
        addStatus: value === "to-let" ? ["To Let"] : value === "let-agreed"  ? ["Let","Let Agreed"] : ["Let", "Let Agreed", "To Let"]
      })
    }
  }

  // getRefinement = () => {
  //     let sales = ["For Sale", "Sale Agreed", "Sold"]
      
  //     if (this.state.addStatus==="sold") {
  //       sales = ["Sold"]
  //       return sales
  //     }
  //     else if(this.state.addStatus==="for-sale"){
  //       sales=["For Sale", "Sale Agreed"]
  //       return sales
  //     }
  //     else{
  //       return sales
  //     }
  // }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      navigate(searchStateToUrl(this.props, searchState, this.props.locationname), searchState)
    }, updateAfter)
    this.setState({ searchState })
  }
  
  // 

  // Map view
  mapView = (e) => {
    navigate(this.props.location.pathname.replace("property", "property-map"))
  }
  // Map View

  // Grid view
   gridView = (e) => {
    this.setState({ gridview: true},()=>{console.log('gridview', this.state.gridview)})    
  }
  // Grid view
  listView = (e) => {
    this.setState({ gridview: false},()=>{console.log('listview', this.state.gridview)})
  }

  // Map View

// Search filter text
searchFilterText = e => {
  //console.log("test");
  // $(".search_text").toggleClass("active")
  this.setState({ infoText: !this.state.infoText })
  //$(".result-header").toggleClass("searchText")
}
// Search filter text
componentDidMount() {
  if(sessionStorage.getItem('searchindex') != null){
    // if(sessionStorage.getItem('tabView') != null && sessionStorage.getItem('tabView') === 'mapview'){
    // $(".results-view").addClass("map-veiw-content");
    // }

    var idelement = "item"+sessionStorage.getItem('searchindex');
    setTimeout(function() { 
      var elmnt = document.getElementById(idelement.toString());
      if(elmnt){
        
      elmnt.scrollIntoView();      
      sessionStorage.removeItem('searchindex')
      }
  }, 500);  
}
else {
  // load on top
$(document).ready(function(){
 $("html, body").scrollTop(0)
});
// load on top
}
}
  render() {
    setTimeout(function() { 
    document.querySelectorAll(".ais-Pagination a.ais-Pagination-link").forEach(node => {
      // node.href.setAttribute('href', 'javascript:void(0)')
     node.href="javascript:void(0)"
    })
  }, 1000);
//     debugger
//     if(typeof s !== sessionStorage.getItem('searchindex')){
//       // if(sessionStorage.getItem('tabView') != null && sessionStorage.getItem('tabView') === 'mapview'){
//       // $(".results-view").addClass("map-veiw-content");
//       // }

//       var idelement = "item"+sessionStorage.getItem('searchindex');
//       setTimeout(function() { 
//         var elmnt = document.getElementById(idelement.toString());
//         if(elmnt){
          
//         elmnt.scrollIntoView();      
//         sessionStorage.removeItem('searchindex')
//         }
//     }, 1000);  
//   }
//   else {
//     // load on top
//  $(document).ready(function(){
//    $("html, body").scrollTop(0)
//  });
//  // load on top
//  }
    // Capitalize
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    // Capitalize

    const searchParams = parseSearchUrl(this.props.ptype, this.props.fullpathname, this.props.pcategorytype);

    if (searchParams.areas == "") {
      searchParams.areas = "london";
    }

    const h1 = propertyH1(searchParams, this.props.pcategorytype);

    const h1desc = propertyDesc(searchParams);

    const areaName = searchParams.areas;

    const pType = searchParams.pType;

    let propertyType = "";
    if (searchParams.type == "") {
      propertyType = "properties "
    } else {
      propertyType = searchParams.type
    }

    const actionStr = ACTION_TYPES[pType];

    var description = `Explore our latest collection of ${capitalize(propertyType)} ${actionStr} ${searchParams.areas}. Contact Crisp Cowley estate agents in ${searchParams.areas} to find the right property for you.`

    const isPageLoadBg = (typeof this.props.fullpathname === "undefined");
    var split_sort_by = this.props.location.pathname.split('sortby-');
    var sort_by = split_sort_by.length > 1 ? split_sort_by[1] : '';
    var replaceH1 = h1.replace(/-/g, ' ');
    //replaceH1 = replaceH1.replace("Essex", "Essex and Kent");
    var replaceh1SEC = h1desc.replace(/-/g, ' ');
    //replaceh1SEC = replaceh1SEC.replace("Essex", "Essex and Kent");
    var areaReplace = capitalize(areaName).replace("%20", " ")
    //areaReplace = areaReplace.replace("Essex", "Essex and Kent");
    const recordPerPage = 1;

// // total number of the records
// var totalRecords = 3;

// // range of pages in paginator
// var pageRange = 1;
// var currentPage = 0
// const handlePageChange = pageNumber => {
//   window.scroll(0, 0)
//   currentPage = pageNumber
// }
let mystatus = ''
if(this?.state?.addStatus){
  if(Array.isArray(this?.state?.addStatus)){
    mystatus = '(status:"'+(this.state.addStatus).join('" OR status:"')+'")'
  } else {
    mystatus = '(status:"'+(this.state.addStatus)+'")'
  }
}

let myDepartment = ''
if(this.props.pcategorytype){
  if(Array.isArray(this.props.pcategorytype)){
    myDepartment = '(department:"'+(this.props.pcategorytype).join('" OR department:"')+'")'
  } else {
    myDepartment = '(department:"'+(this.props.pcategorytype)+'")'
  }
}

    return (
      <Layout layout="without_banner_template">

        <section className='search-results-wrapper'>
          <SeoResults title={h1} searchParams={searchParams} description={description} location={this.props.fullpathname} pcategorytype={this.props.pcategorytype} />
          <InstantSearch
            indexName={index_name}
            searchClient={searchClient}
            searchState={this.state.searchState}
            onSearchStateChange={this.onSearchStateChange}
            createURL={createURL}
            routing="true"
          >
            
            <div className="d-none">
              {/* <RefinementList
                attribute="search_type"
                defaultRefinement={[this.props.ptype]}
              />
              <RefinementList
                attribute="department"
                defaultRefinement={this.props.pcategorytype}
              />
              <RefinementList
                attribute="status"
                defaultRefinement={this.props.pstatustype}
              />
              <RefinementList
                attribute="publish"
                defaultRefinement={[true]}
              /> */}
            </div>

            <Configure
              hitsPerPage={15}
              filters={`publish:true AND search_type:${this.props.ptype} AND ${myDepartment} AND ${mystatus}`} 
            />

            <div className='result-head'>
              <div className='justify-content-center text-center'>
                <Container>
                  <h1>{replaceH1}</h1>
                  <Col lg={`9`} md={`11`} className={`m-auto`}>
                    <p className="result-intro"><CustomStats /> properties for {this.props.ptype === "sales" ? "sale" : "rent"}  <a
                          href="javascript:void(0)"
                          onClick={this.searchFilterText}
                        >
                          {" "}
                          <i className="info-icon"></i>
                        </a></p>
                        <p className={`search_text ${this.state.infoText ? 'active' : ''}`} >
                        Explore our wide range of {replaceH1} with Crisp Cowley. To filter these results, use our filter below, or for more information about residential properties for {this.props.ptype === "sales" ? "sale" : "rent"} in Bath, please 
                  <Link to="/contact-crisp-cowley-estate-agents/">contact us.</Link>
                </p>
                  </Col>
                </Container>
              </div>
            </div>
            <PropertyFilter type={this.props.ptype}
              handleAdditionalStatus={this.handleAdditionalStatus}
              department={this.props.pcategorytype}
              sort_by={sort_by} />
            <div className="d-none">
              {/* <SearchBox /> */}
              <CustomminpriceSelectbox attribute="price" type={this.props.ptype} />
              <CustommaxpriceSelectbox attribute="price" type={this.props.ptype} />
              {/* <CustombuildingSelectbox attribute="building" type={this.props.ptype} department={this.props.pcategorytype} /> */}
              {/* <CustomavailabiltySelectbox attribute="status" type={this.props.ptype} department={this.props.pcategorytype} /> */}
              {/* <CustombedroomSelectbox attribute="bedroom" /> */}
              {/* {
                this.props.ptype === "sales" ?
                  <CustomToggleRefinement
                    attribute="status"
                    label="Include Sold"
                    value={['Sold Subject To Contract']}
                    defaultRefinement={includeSold}
                    includeSoldLet={includeSold}
                  />
                  :
                  <CustomToggleRefinement
                    attribute="status"
                    label="Include Let"
                    value={['Let Agreed']}
                    defaultRefinement={includeLet}
                    includeSoldLet={includeLet}
                  />
              } */}
              <CustomSortByFilter
                defaultRefinement={index_name}
                className="search-sory-by"
                items={[
                  // { value: index_name + "_most_recent", label: "Most Recent" },
                  { value: index_name, label: "Highest Price" },
                  { value: index_name + "_price_asc", label: "Lowest Price" },
                ]}
              />

            </div>
            <section className="search-results">
<Container>
   
    <Row>
        <Col lg={12}>
    <div className="d-flex flex-wrap">
              <CustomInfiniteHits
                location={this.props.fullpathname}
                tag={this.props.ptypetag}
                gridview={this.state.gridview}
              />
              {!isMobile &&
              <PaginationDesktop />
              }
              <CustomNoStats
                location={this.props.fullpathname}
                status={this.props.ptype}
                department={this.props.pcategorytype}
              />
                 </div>
                 </Col>
                 
                 </Row>
    </Container>
    </section>
           
           
          </InstantSearch>
          {/* <SearchResults /> */}
          {/* <div className='pagnation-blk'>
          <Container>
          <Row>
                        <Col>
                            <Pagination
                                itemClass="page-item" // add it for bootstrap 4
                                linkClass="page-link" // add it for bootstrap 4
                                activePage={currentPage}
                                itemsCountPerPage={recordPerPage}
                                totalItemsCount={totalRecords}
                                pageRangeDisplayed={pageRange}
                                onChange={handlePageChange}
                            />
                        </Col>
                    </Row>
                    </Container>
                    </div> */}
        </section>
        </Layout>
    )
  }
}

export default SearchResultsTemplate
