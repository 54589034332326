import React, { useState, useEffect } from 'react'
import "./PropertyFilter.scss"
import { Container, Form, Modal, Button } from 'react-bootstrap'
import _ from "lodash"
import { find } from "lodash"
import Select from 'react-select';
import { navigate } from "@reach/router";
import { navigate as gatsbyNavigate } from "gatsby";
import { propertyTypes, AvailabilitySales, AvailabilityLettings, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, Sorting } from "../../components/Common/propertyUtils";
import $ from 'jquery'
export const customStyles = (fontSize, borderRadius) => ({
  control: (styles, state) => {
    return {
      ...styles,
      borderColor: state.menuIsOpen ? "transparent" : "#DCDDDE",
      boxShadow: state.menuIsOpen
        ? "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)"
        : "none",
      // height: "56px",
      borderRadius: state.menuIsOpen
        ? `${borderRadius} ${borderRadius} 0 0`
        : borderRadius,
      "&:hover": {
        borderColor: state.menuIsOpen ? "transparent" : "#DCDDDE",
      },
    }
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: "16px",
    fontSize: "inherit",
  }),
  menu: styles => ({
    ...styles,
    margin: "0",
    padding: 0,
    border: 0,
    borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)",
  }),
  menuList: styles => ({
    ...styles,
    margin: "0",
    paddingBottom: "0px",
    paddingTop: "0px"
    // borderBottom: "1px solid #F5F5F5",
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: "inherit",
  }),
  option: styles => ({
    ...styles,
    color: "#393939",
    backgroundColor: "#fff",
    fontSize: "inherit",
    lineHeight: "20px",
    padding: "8px 10px",
    borderTop: "1px solid #F5F5F5",
    WebkitTapHighlightColor: "#fff",
    ":hover": {
      backgroundColor: "#ddd",
      // opacity: 0.7,
    },
  }),
})
function PropertyFilter(props) {
  const styles = {}
  const options = [
    { value: 'buy', label: 'Buy' },
    { value: 'rent', label: 'Rent' }
  ]
  // Common Search Navigate
  const pyOptions = propertyTypes
  const onChangeSellRent = (e) => {
    gatsbyNavigate(`/${e.value}`)
  }
  // 
  // Search Filter States
  const [propertyval, setPropertyVal] = useState('');
  const [availability, setAvailability] = useState('');
  const [areaVal, setAreaVal] = useState('');
  const [minpriceVal, setMinPriceVal] = useState('');
  const [maxpriceVal, setMaxPriceVal] = useState('');
  const [sort, setSort] = useState('');
  const [mobilesort, setMobileSort] = useState(false);
  const [bedVal, setBedVal] = useState('');
  const [minPriceList, setMinPriceList] = useState([]);
  const [maxPriceList, setMaxPriceList] = useState([]);
  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
  const [addStatus, setAddStatus]=useState(null)
  const [initial, setInitial]=useState(false)
  const availabilityOptions = props.type === "sales" ? AvailabilitySales : AvailabilityLettings
  // 
  // Show Hide More Filter
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // 
  ///// Price Filter Set /////

  // Min price
  const onChangeMinPrice = (e) => {
    setSelectedMinPriceValue(e.value);
  }
  // Min price

  // Max Price
  const onChangeMaxPrice = (e) => {
    setSelectedMaxPriceValue(e.value);
  }
  // Max Price

  // Sales price list
  var minPrice = [];
  var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));

  if (minPrice.length > 0) {
  } else {
    minPrice.push(priceminRangeResiSale[0])
  }

  if (maxPrice.length > 0) {
  } else {
    maxPrice.push(pricemaxRangeResiSale[0])
  }
  // Sales price list

  // Lettings price list
  var minPriceLettings = [];
  var maxPriceLettings = pricemaxRangeResiRent.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiRent.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPriceLettings.push(items));

  if (minPriceLettings.length > 0) {
  } else {
    minPriceLettings.push(priceminRangeResiRent[0])
  }

  if (maxPriceLettings.length > 0) {
  } else {
    maxPriceLettings.push(pricemaxRangeResiRent[0])
  }
  // Lettings price list

  // 
  useEffect(() => {
    // 
    if (_.isEmpty(minPriceList)) {
      if (props.type === "lettings") {
        setMinPriceList(priceminRangeResiRent);
        setMaxPriceList(pricemaxRangeResiRent);
      } else {
        setMinPriceList(priceminRangeResiSale)
        setMaxPriceList(pricemaxRangeResiSale)
      }
    }
    //

    // Page when load field in text boxes will same
    const url = typeof window !== 'undefined' ? window.location.href : ''
    let pathUri = url.split("/")
    for (let vi = 1; vi <= pathUri.length; vi++) {
      // check for area
      if (typeof pathUri[vi] === "undefined") {
        continue
      }

      // Property type
      if (pathUri[vi].indexOf("type-") >= 0) {
        setPropertyVal(pathUri[vi].replace("type-", ""))
      }
      // Property type

  // Sort
  if (pathUri[vi].indexOf("sortby-") >= 0) {
    setSort(pathUri[vi].replace("sortby-", ""))
  }
  // Sort

      // Availability
      if (pathUri[vi].indexOf("availability-") >= 0) {
        setAvailability(pathUri[vi].replace("availability-", ""))
      }
      // Availability

      // Area
      if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-london") == -1)) {
        setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
      }
      // Area

      // Price
      if (
        pathUri[vi].indexOf("between-") >= 0 ||
        pathUri[vi].indexOf("over-") >= 0 ||
        pathUri[vi].indexOf("under-") >= 0
      ) {
        let priceFilt1 = pathUri[vi].split("over-")
        if (priceFilt1[1]) {
          setMinPriceVal(priceFilt1[1])
        }
        let priceFilt2 = pathUri[vi].split("under-")
        if (priceFilt2[1]) {
          setMaxPriceVal(priceFilt2[1])
        }
        let priceFilt3 = pathUri[vi].split("between-")
        if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-and-")
          setMinPriceVal(priceFilt4[0])
          setMaxPriceVal(priceFilt4[1])
        }
      }
      // Price

      // Bedrooms
      if (pathUri[vi].indexOf("-and-more-") >= 0) {
        setBedVal(pathUri[vi].replace("-and-more-bedrooms", ""))
      }
      // Bedrooms

    }
    // Page when load field in text boxes will same
  }, []);
  // 
  //  Submit Form on Mobile Sort
  // useEffect(() => {

  //     submitSearch();      

  // },[mobilesort])
  const HandleSort = (e) => {
    const url = typeof window !== 'undefined' ? window.location : ''
    var location_path_remove = url.pathname.replace('sortby-price-desc/', '').replace('sortby-price-asc/', '')
    var searchFilterResults =e ? 'sortby-' + e+"/":"";
    navigate(location_path_remove+searchFilterResults);
  }
  // Submit search
  const submitSearch = (e) => {

    if (show == true) {
      setShow(false);
    }

    //e.preventDefault();
    // var areaValueInput = $(".location-box input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();

    var areaValue = areaVal.toLowerCase()
    // if(areaValueInput) {
    //     areaValue = areaValueInput
    // } else {
    //     areaValue = "london"
    // }
    var bedroomValue = bedVal ? bedVal : $(".bedroom-input input[type='hidden']").val();
    var buildingValue = propertyval ? propertyval : $(".property input[type='hidden']").val();
    var availabilityValue = availability ? availability : "";
    let priceUrl = ""
    if (maxpriceVal || minpriceVal) {
      if (minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
        priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
      } else
        if ((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
          priceUrl = "under-" + maxpriceVal
        }
        else if (minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
          priceUrl = "over-" + minpriceVal
        }
    }

    var searchFilterResults = ""

    if (areaValue) {
      if (props.type === "sales") {
        searchFilterResults = "/property/for-sale/in-" + areaValue + "/";
      } else {
        searchFilterResults = "/property/to-rent/in-" + areaValue + "/";
      }
    }

    if (priceUrl) {
      searchFilterResults += priceUrl + "/";
    }

    if (availabilityValue) {
      // searchFilterResults += "availability-" + availability + "/";
       props.handleAdditionalStatus(addStatus)
    }
    if(initial){
      props.handleAdditionalStatus(addStatus)
    }

    if (buildingValue) {
      searchFilterResults += "type-" + buildingValue + "/";
    }

    if (bedroomValue && bedroomValue != "0") {
      searchFilterResults += bedroomValue + "-and-more-bedrooms/";
    }

   
    if (sort) {
      searchFilterResults += 'sortby-' + sort+"/";
    }
    navigate(searchFilterResults);
  }
  // Submit search

  const handleAvailability=(value)=>{
    if(value===""){
      setInitial(true)
    }
    setAvailability(value)
    setAddStatus(value)
   
  }
const [opened, setOpened] = useState(false)
const [opened1, setOpened1] = useState(false)
const [opened2, setOpened2] = useState(false)
const [opened3, setOpened3] = useState(false)
const [opened4, setOpened4] = useState(false)
  return (
    <Container>
      <Form action="javascript:;" method="post" onSubmit={submitSearch}>
        <div className={`filter-wrapper`}>
          <div className="select-dropdown type-dropdown">
            <Select
              options={propertyTypes}
              value={find(propertyTypes, ['label', (props.type === "sales" ? 'To Buy' : 'To Rent')])}
              isSearchable={false}
              classNamePrefix={"select-opt"}
              className={opened4 ? "select-control field-select is-open" : "select-control field-select"}
              onChange={e => { onChangeSellRent(e)}}
              styles={{ ...customStyles("1.4rem", "8px"), ...styles }}
              onMenuOpen={() => setOpened4(true)}
              onMenuClose={() => setOpened4(false)}
            />
          </div>
          <div className="select-dropdown price-dropdown">
            <Select
              options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : (props.type === "sales" ? minPrice : minPriceLettings)}
              value={minPriceList.find(obj => obj.value === minpriceVal)}
              isSearchable={false}
              classNamePrefix={"select-opt"}
              placeholder="Min Price"
              className={opened ? "select-control field-select is-open" : "select-control field-select"}
              onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
              styles={{ ...customStyles("1.4rem", "8px"), ...styles }}
              onMenuOpen={() => setOpened(true)}
              onMenuClose={() => setOpened(false)}
            />
          </div>
          <div className="select-dropdown price-dropdown">
            <Select
              options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : (props.type === "sales" ? maxPrice : maxPriceLettings)}
              value={maxPriceList.find(obj => obj.value === maxpriceVal)}
              isSearchable={false}
              classNamePrefix={"select-opt"}
              className={opened1 ? "select-control field-select is-open" : "select-control field-select"}
              placeholder="Max Price"
              onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
              styles={{ ...customStyles("1.4rem", "8px"), ...styles }}
              onMenuOpen={() => setOpened1(true)}
              onMenuClose={() => setOpened1(false)}

            />
          </div>
          <div className="select-dropdown">
            <Select
              options={Sorting}
              placeholderValue={sort ? sort + " +" : "Sort"}
              value={Sorting.find(obj => obj.value === sort)}
              onChange={e => { setSort(e.value); setMobileSort(true); HandleSort(e.value); }}
              isSearchable={false}
              classNamePrefix={"select-opt"}
              className={opened2 ? "select-control field-select is-open" : "select-control field-select"}
              placeholder="Sort"
              styles={{ ...customStyles("1.4rem", "8px"), ...styles }}
              onMenuOpen={() => setOpened2(true)}
              onMenuClose={() => setOpened2(false)}


            />
          </div>
          <div className="select-dropdown">
            <Select
              options={availabilityOptions}
              placeholderValue={availability ? availability : "Availability"}
              value={availabilityOptions.find(obj => obj.value === availability)}
              onChange={e => handleAvailability(e.value)}
              isSearchable={false}
              classNamePrefix={"select-opt"}
              className={opened3 ? "select-control field-select is-open" : "select-control field-select"}
              styles={{ ...customStyles("1.4rem", "8px"), ...styles }}
              onMenuOpen={() => setOpened3(true)}
              onMenuClose={() => setOpened3(false)}

            />
          </div>
          <div className='submit-filter'>
            <button className='submit-icon' type="submit">Update</button>
          </div>
        
        </div>
      </Form>
    </Container>
  )
}

export default PropertyFilter