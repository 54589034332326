import gql from "graphql-tag"


/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{search_type:$status, department:$department, publish:true}) {
        id
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        crm_negotiator_id
        bedroom
        reception
        bathroom
        price_qualifier
        description
    }

}`
const NoResultPropertiesLettings = gql`
query NoResultProperties{
    properties(limit:6, where:{search_type:"lettings", _or: [{ department: "residential" }, { department: "long_term" }, { department: "short_term" }], publish:true}) {
        id
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        crm_negotiator_id
        bedroom
        reception
        bathroom
        price_qualifier
        description
    }

}`





export const Queries = {
  NoResultProperties,
  NoResultPropertiesLettings
};


