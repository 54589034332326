import React, { useState, useEffect } from "react";
import {NoResultProperties} from '../../queries/common_use_query';
import PropertyCard from "../PropertyCard/PropertyCard";
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
const PropertyNoResult = (props) => {

    
    
    const[department, setDepartment] = useState('residential');

    // useEffect(()=>{
    //     props.department && setDepartment(props.department);
    // },[props.department]);

    const {loading:loading, error:error, data:data} = NoResultProperties(props.status, department);

    console.log(data)
    return (
        <React.Fragment>
            {
                data && data.properties.length > 0 &&
                <>
                          <Container className="shell">
            <Row className="property-filter__row">
              <Col lg={12} className="no-properties-text mb-5">
                <div className="fx-ma-wi">
                <h3 className="mb-0">Unfortunately, we do not currently have any properties that match your search criteria.</h3>
                <br/>
           
                    <p class="results-page-wrapper">We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.</p>
                    </div>
                    </Col>
            </Row>
</Container>
                    {
                        data.properties.map((hit, index) => {

                            // property details url structure
                            let uriStr = "";
                    
                            if (hit.search_type === "sales" && hit.department === 'residential') {
                                uriStr = `property-for-sale/`
                            } else if (hit.search_type === "lettings" && hit.department === 'residential') {
                                uriStr = `property-to-rent/`
                            }
                            // property details url structure

                            return (
                                <PropertyCard 
                                    price={"£"+hit.price.toLocaleString()}
                                    propertyid={hit.id}
                                    location={hit.display_address}
                                    priceQualifier={hit.price_qualifier}
                                    description={hit.description}
                                    bedroomsCount={hit.bedroom}
                                    bathroomsCount={hit.bathroom}
                                    receptionsCount={hit.reception}
                                    propertyVideo={hit.virtual_tour}
                                    propertyImages={hit.images}                                    
                                    propertyDetailsLink={`/${uriStr}${hit.slug}-${hit.id}/`}
                                    propertySearchType={hit.search_type}
                                    status={hit.status}
                                    title={hit.title}
                                    imagetransforms={hit.imagetransforms}
                                    noResulttag={"noResultsProperty"}
                                />
                            )
                        })
                    }
                </>
            }
        </React.Fragment>
    )
}

export default PropertyNoResult;
