// Ptypes
export const propertyTypes = [
    { value: 'property/for-sale/in-bath', label: 'To Buy' },
    { value: 'property/to-rent/in-bath', label: 'To Rent' },
]
// Property type
export const resiSalesPropertyType = [
    {label: "Property Type", value: ""},
    {label: "Apartments", value: "apartment"},
    {label: "Houses", value: "house"},
    {label: "Maisonettes", value: "maisonette"},
    {label: "Offices", value: "office"},    
]

export const resiLettingsPropertyType = [
    {label: "Property Type", value: ""},
    {label: "Apartments", value: "apartment"},
    {label: "Houses", value: "house"},
    {label: "Maisonettes", value: "maisonette"},
    {label: "Offices", value: "office"},
    
]
export const AvailabilitySales = [
    {label: "ALL", value: ""},
    {label:"FOR SALE", value:"for-sale"},
    {label: "SOLD", value: "sold"}
    // {label: "Sold STC", value: "sold-stc"},
    // {label: "Under Offer", value: "under-offer"},    
]
export const AvailabilityLettings = [
    {label: "ALL", value: ""},
    // {label: "Available", value: "to-let"},
    {label: "To Let", value: "to-let"},
    {label: "Let Agreed", value: "let-agreed"},
]
// Property type

// Price Min Residential Sales
export const priceminRangeResiSale = [
    {label: "MIN PRICE", value: "0"},
    {label: "£50,000", value: "50000"},
    {label: "£60,000", value: "60000"},
    {label: "£70,000", value: "70000"},
    {label: "£80,000", value: "80000"},
    {label: "£90,000", value: "90000"},
    {label: "£100,000", value: "100000"},
    {label: "£120,000", value: "120000"},
    {label: "£140,000", value: "140000"},
    {label: "£160,000", value: "160000"},
    {label: "£180,000", value: "180000"},
    {label: "£200,000", value: "200000"},
    {label: "£250,000", value: "250000"},
    {label: "£300,000", value: "300000"},
    {label: "£350,000", value: "350000"},
    {label: "£400,000", value: "400000"},
    {label: "£500,000", value: "500000"},
    {label: "£600,000", value: "600000"},
    {label: "£700,000", value: "700000"},
    {label: "£800,000", value: "800000"},
    {label: "£900,000", value: "900000"},
    {label: "£1,000,000", value: "1000000"},
    {label: "£1,250,000", value: "1250000"},
    {label: "£1,500,000", value: "1500000"},
    {label: "£1,750,000", value: "1750000"},
    {label: "£2,000,000", value: "2000000"},
    {label: "£2,500,000", value: "2500000"},
    {label: "£3,000,000", value: "3000000"},
    {label: "£4,000,000", value: "4000000"},
    {label: "£5,000,000", value: "5000000"},
    {label: "£6,000,000", value: "6000000"},
    {label: "£7,000,000", value: "7000000"},
    {label: "£8,000,000", value: "8000000"},
    {label: "£9,000,000", value: "9000000"},
    {label: "£10,000,000", value: "10000000"}
]
// Price Min Residential Sales
  
// Price Max Residential Sales
export const pricemaxRangeResiSale = [
    {label: "MAX PRICE", value: "0"},
    {label: "£10,000,000 +", value: "10000000"},
    {label: "£9,000,000", value: "9000000"},
    {label: "£8,000,000", value: "8000000"},
    {label: "£7,000,000", value: "7000000"},
    {label: "£6,000,000", value: "6000000"},
    {label: "£5,000,000", value: "5000000"},
    {label: "£4,000,000", value: "4000000"},
    {label: "£3,000,000", value: "3000000"},
    {label: "£2,500,000", value: "2500000"},
    {label: "£2,000,000", value: "2000000"},
    {label: "£1,750,000", value: "1750000"},
    {label: "£1,500,000", value: "1500000"},
    {label: "£1,250,000", value: "1250000"},
    {label: "£1,000,000", value: "1000000"},
    {label: "£900,000", value: "900000"},
    {label: "£800,000", value: "800000"},
    {label: "£700,000", value: "700000"},
    {label: "£600,000", value: "600000"},
    {label: "£500,000", value: "500000"},
    {label: "£400,000", value: "400000"},
    {label: "£350,000", value: "350000"},
    {label: "£300,000", value: "300000"},
    {label: "£250,000", value: "250000"},
    {label: "£200,000", value: "200000"},
    {label: "£180,000", value: "180000"},
    {label: "£160,000", value: "160000"},
    {label: "£140,000", value: "140000"},
    {label: "£120,000", value: "120000"},
    {label: "£100,000", value: "100000"},
    {label: "£90,000", value: "90000"},
    {label: "£80,000", value: "80000"},
    {label: "£70,000", value: "70000"},
    {label: "£60,000", value: "60000"},
    {label: "£50,000", value: "50000"}
]
// Price Max Residential Sales
  
// Price Min Residential Lettings
export const priceminRangeResiRent = [
    {label: "MIN PRICE", value: "0"},
    {label: "£50 pcm", value: "50"},
    {label: "£100 pcm", value: "100"},
    {label: "£200 pcm", value: "200"},
    {label: "£300 pcm", value: "300"},
    {label: "£400 pcm", value: "400"},
    {label: "£500 pcm", value: "500"},
    {label: "£600 pcm", value: "600"},
    {label: "£700 pcm", value: "700"},
    {label: "£800 pcm", value: "800"},
    {label: "£900 pcm", value: "900"},
    {label: "£1,000 pcm", value: "1000"},
    {label: "£1,250 pcm", value: "1250"},
    {label: "£1,500 pcm", value: "1500"},
    {label: "£1,750 pcm", value: "1750"},
    {label: "£2,000 pcm", value: "2000"},
    {label: "£2,250 pcm", value: "2250"},
    {label: "£2,500 pcm", value: "2500"},
    {label: "£2,750 pcm", value: "2750"},
    {label: "£3,000 pcm", value: "3000"},
    {label: "£3,500 pcm", value: "3500"},
    {label: "£4,000 pcm", value: "4000"},
    {label: "£5,000 pcm", value: "5000"},
    {label: "£7,500 pcm", value: "7500"},
    {label: "£10,000 pcm", value: "10000"}
]
// Price Min Residential Lettings
  
// Price Max Residential Lettings
export const pricemaxRangeResiRent = [
    {label: "MAX PRICE", value: "0"},
    {label: "£10,000 pcm +", value: "10000"},
    {label: "£7,500 pcm", value: "7500"},
    {label: "£5,000 pcm", value: "5000"},
    {label: "£4,000 pcm", value: "4000"},
    {label: "£3,500 pcm", value: "3500"},
    {label: "£3,000 pcm", value: "3000"},
    {label: "£2,750 pcm", value: "2750"},
    {label: "£2,500 pcm", value: "2500"},
    {label: "£2,250 pcm", value: "2250"},
    {label: "£2,000 pcm", value: "2000"},
    {label: "£1,750 pcm", value: "1750"},
    {label: "£1,500 pcm", value: "1500"},
    {label: "£1,250 pcm", value: "1250"},
    {label: "£1,000 pcm", value: "1000"},
    {label: "£900 pcm", value: "900"},
    {label: "£800 pcm", value: "800"},
    {label: "£700 pcm", value: "700"},
    {label: "£600 pcm", value: "600"},
    {label: "£500 pcm", value: "500"},
    {label: "£400 pcm", value: "400"},
    {label: "£300 pcm", value: "300"},
    {label: "£200 pcm", value: "200"},
    {label: "£100 pcm", value: "100"},
    {label: "£50 pcm", value: "50"}
]
// Price Max Residential Lettings
  
// Bedrooms
export const bedroomsRange = [
    {label: "Bedrooms", value: ""},
    {label: "1+", value: "1"},
    {label: "2+", value: "2"},
    {label: "3+", value: "3"},
    {label: "4+", value: "4"},
    {label: "5+", value: "5"},
    {label: "6+", value: "6"},
    {label: "7+", value: "7"},
    {label: "8+", value: "8"},
    {label: "9+", value: "9"},
    {label: "10+", value: "10"},
]
// Bedrooms

// Bedrooms
export const Sorting = [
    {label: "SORT", value: ""},
    // {label: "Most Recent", value: "most-recent"},
    {label: "HIGHEST PRICE", value: "price-desc"},
    {label: "LOWEST PRICE", value: "price-asc"},
]
// Bedrooms