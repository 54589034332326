import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col} from "react-bootstrap";
import FeaturedPropertiesImg1  from "../../images/featured-properties-img1.jpg";
import FeaturedPropertiesImg2  from "../../images/featured-properties-img2.jpg";
import FeaturedPropertiesImg3  from "../../images/featured-properties-img3.jpg";
import FeaturedPropertiesImg4  from "../../images/featured-properties-img4.jpg";
import {FavIcon } from "../icons";
import "./searchResults.scss";
import Pagination from "react-js-pagination";
const SearchResults = () => {
    
    return(
<section className="search-results">
<Container>
   
    <Row>
        <Col lg={12}>
    <div className="d-flex flex-wrap">
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                         </div>                         
                     
                 </div>
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                         </div>                         
                     
                 </div>
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                         </div>                         
                     
                 </div>
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                         </div>                         
                     
                 </div>
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                         </div>                         
                     
                 </div>
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                         </div>                         
                     
                 </div>
                 <div className="search-results-card-main">
                     <div className="search-results-img-content">
                         <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                         <picture>
                         <source media="(min-width:992px)" srcSet={FeaturedPropertiesImg1} />
                         <source media="(min-width:768px)" srcSet={FeaturedPropertiesImg1} />
                         <img src={FeaturedPropertiesImg1} alt="" />
                         </picture>
                         </div>
                         {/* <span className="tag">UNDER OFFER</span> */}
                     </div>
                     <div className="search-results-text-content">
                         
                         <div className="property-description">
                             <p>West Kington, Chippenham</p>
                         </div>
                         <div className="property-amount-text">
                             Offers in excess of £1,750,000
                         </div>
                         <div classname="bedroom">6 bedroom detatched house</div>
                        
                                               
                     </div>
                 </div>
                 
                                  </div>
                 </Col>
                 </Row>
    </Container>
    </section>
)
    }
export default SearchResults
