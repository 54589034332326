import React, { useState } from "react"
import "./PropertyCard.scss"
import { Row } from "react-bootstrap"
import { ShowProcessedImage } from "../Common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
// import NoImage from "../../images/no-image.png"
import { Link } from "gatsby"
import moment from 'moment';
import $ from "jquery"
import NoImage from "../../images/no-image.png"
import ImageTransform from "../Common/ggfx-client/module/components/image-transform";
const PropertyCard = ({
  propertyid,
  status,
  gridview,
  price,
  priceQualifier,
  title,
  location,
  description,
  day,
  bedroomsCount,
  receptionsCount,
  bathroomsCount,
  floorarea,
  floorarea_type,
  propertyVideo,
  propertyImages,
  propertyDetailsLink,
  propertySearchType,
  noResulttag,
  userObjects,
  savedSearchParams,
  imagetransforms
}) => {
    //property description
    let text_truncate = function (str, length, ending) {
      if ($(window).width() > 1400) {
        length = 220
      } else {
        length = 160
      }
      //
      if (ending == null) {
        ending = "... "
      }
      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending
      } else {
        return str
      }
    }
    //var propertyintro = removeTags(description);
    var propertyintro = description && description.replace(/\<br\>/g, " ")
    //property description
    let address1=location.split(",");
    // Ensure at least one element remains after popping
    if (address1.length > 1) {
      address1.pop();  // Remove the last element
    }
    if (address1.length > 1) {
      address1.pop();  // Remove the last element again
    }

    let displayAddress1=address1.join();

    let address2=location.split(",");
    let first=address2[address2.length-2];
    let second=address2[address2.length-1];

    let displayAddress2=`${first}, ${second}`;
    const calculateDaysLeft = (startDate, endDate) => {
      if (!moment.isMoment(startDate)) startDate = moment(startDate);
      if (!moment.isMoment(endDate)) endDate = moment(endDate);
    
      return endDate.diff(startDate, "days");
    }

    const daysLeft = calculateDaysLeft(day, new Date());
    let processedImages = JSON.stringify({});
    if (imagetransforms?.images_Transforms) {
      processedImages = imagetransforms?.images_Transforms;
    }
   
  return (
    <div id={`item${propertyid}`} className="search-results-card-main">
      <Link onClick={() => {sessionStorage.setItem('searchindex', propertyid)}} to={propertyDetailsLink} >
    <div className="search-results-img-content">
        <div className="feature-property-img-wrap img-hover-zoom video-wrap">
        {/* <picture>
        <source media="(min-width:992px)" srcSet={propertyImages[0]?.url} />
        <source media="(min-width:768px)" srcSet={propertyImages[0]?.url} />
        <img src={propertyImages[0]?.url} alt="" />
        </picture> */}
        {noResulttag === "noResultsProperty" ? (
                          <ImageTransform
                          imagesources={propertyImages && propertyImages[0]?.url}
                          // renderer="srcSet"
                          imagename={"property.images.searchResults"}
                          attr={{ alt: displayAddress1+ " - Crisp Cowley", className: "" }}
                          imagetransformresult={processedImages}
                          id={propertyid}
                        />
                          // <img
                          //   src={propertyImages && propertyImages[0]?.url}
                          //   alt={location + " - Sandfords"}
                          // />
                     ) : (
        propertyImages && propertyImages[0] ? (
                  <ShowProcessedImage
                        images={propertyImages && propertyImages[0]}
                        attr={{
                          className: "mb-0 img-fluid",
                          alt: location + " - Crisp cowley",
                        }}
                        transforms={imageConfig.property.searchResults.sizes}
                      />)
                      :
                  <img src={NoImage}  className="details-no-image" alt="no-image"/>
                    )
          }
          {status!=="For Sale" ?
            <span className={`tag_name`}>{status === "Sale Agreed" ? "UNDER OFFER" : status?.toUpperCase()}</span> 
          : daysLeft < 40 ?
          <span className={`tag_name`}>NEW</span> 
          : null
          }
          
        </div>
       

        {/* <span className="tag">UNDER OFFER</span> */}
    </div>
    </Link>
    <div className="search-results-text-content">
       
        <div className="property-description">          
            <p className="hide-mobile-only"> <Link to={propertyDetailsLink}>{displayAddress1}</Link></p>
           <div className="show-mobile-only"> 
            <Link onClick={() => {sessionStorage.setItem('searchindex', propertyid)}} to={propertyDetailsLink}  >
              <p className="address1">{displayAddress1}</p>
              {/* <p className="address2">{displayAddress2}</p> */}
            </Link>
            </div>
        </div>
       
        <div className="property-amount-text">
            {priceQualifier ? priceQualifier==="POA"?priceQualifier:`${priceQualifier} ${price}` : `${price}`}
        </div>
        <div className="bedroom">{title}</div>
        </div>                         
       
</div>
    // <div className={`property-item-wrapper ${gridview ? "grid-view-wrapper" : ""}`}>
    //   <div className="property-item__row">
    //     <div className="property-item__row-inner">
    //       <div className="property-item__gallery d-md-grid">
    //         {propertyImages && propertyImages.length > 0 ? (
    //           <div className="featured-image img-zoom">
    //             <picture>
    //               <Link to={propertyDetailsLink}>
    //                 {noResulttag === "noResultsProperty" ? (
    //                   <div className="gatsby-image-wrapper featured-image fromalg1">
    //                     <picture>
    //                       <img
    //                         src={propertyImages && propertyImages[0]?.url}
    //                         alt={location + " - David Astburys"}
    //                       />
    //                     </picture>
    //                   </div>
    //                 ) : (
    //                   <ShowProcessedImage
    //                     images={propertyImages && propertyImages[0]}
    //                     attr={{
    //                       className: "mb-0 img-fluid",
    //                       alt: location + " - David Astburys",
    //                     }}
    //                     transforms={imageConfig.property.searchResults.sizes}
    //                   />
    //                   // <></>
    //                 )}
    //               </Link>
    //             </picture>
    //             <Link className="btn-link" to={propertyDetailsLink}>
    //               <i class="icon icon-track-slider-link-arrow"></i>
    //             </Link>
    //           </div>
    //         ) : (
    //           <div className="featured-image img-zoom">
    //             <picture>
    //               <Link to={propertyDetailsLink}>
    //                 <img src={NoImage} alt={location + " - David Astburys"} />
    //               </Link>
    //             </picture>
    //             <Link className="btn-link" to={propertyDetailsLink}>
    //               <i class="icon icon-track-slider-link-arrow"></i>
    //             </Link>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="property-item__text">
    //         <div className="property-text-inner">
    //         <Link to={propertyDetailsLink}>
    //           <h2>{location}</h2>
    //         </Link>
    //         {
    //           propertySearchType === "sales" ? 
    //           <h3>{priceQualifier} {price} </h3> 
    //           : 
    //           <h3>{price} {priceQualifier === "Weekly" ? "per week" : (priceQualifier === "Monthly" ? "per month" : priceQualifier) }</h3>
    //         }          
    //         <div className="property-icons">
    //           <div>
    //             <i className="icon-bedroom-list"></i>{bedroomsCount}
    //           </div>
    //           <div>
    //             <i className="icon-bathroom-list"></i>{bathroomsCount}
    //           </div>
    //           <div>
    //             <i className="icon-reception-list"></i>{receptionsCount}
    //           </div>
    //           <div>
    //             <i className="icon-size-list"></i>{Math.round(floorarea)}{` ${floorarea_type}`}
    //           </div>
    //         </div>
    //         {gridview == false && description ?
    //         <div className="property-item__description">
    //           <p>
    //             {text_truncate(propertyintro)}
    //           </p>
    //         </div>
    //         : "" }
    //         {gridview == false ?
    //         <div className="property-actions">
    //           <Link to={propertyDetailsLink}>View Details</Link>              
    //         </div>
    //         :"" }
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default PropertyCard

